.error__page {
    background: $text-color-8;
    height: 100vh;
    position: relative;
    &-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        h1 {
            font-size: 300px;
            line-height: 310px;
            color: $white;
        }
        p {
            font-size: 20px;
            margin-bottom: 40px;
            line-height: 30px;
        }
    }
}

@media (max-width: 991px) {
    .error__page {
        &-content {
            p {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
@media (max-width: 575px) {
    .error__page {
        &-content {
            h1 {
                font-size: 140px;
                line-height: 150px;
            }
        }
    }
}