/*==========================================================================
Common Css
==========================================================================*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
body {
	font-family: $body-font;
	color: $body-color;
	font-size: 16px;
	line-height: 26px;
  	font-weight: 400;
}
img {
	max-width: 100%;
	height: auto;
	transition: all 0.4s ease-out 0s;
}
a {
  outline: none;
  color: inherit;
  text-decoration: none;
}
a,
button,
i {
	text-decoration: none;
	color: inherit;
}
a:focus,
a:hover {
	text-decoration: none;
	color: inherit;
}
.section-padding {
	padding: 150px 0px;
}
@media (max-width: 575px) {
	.section-padding {
		padding: 120px 0px;
	}
}
.display-none {
	display: none;
}
.img__full {
	width: 100%;
}
/*==========================================================================
Form
==========================================================================*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid $border3;
  border-color: transparent;
  border-radius: 0px;
  background: $primary-color;
  color: $white;
  line-height: 1;
  padding: 0.6em 1em 0.4em;
}
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: transparent;
}
button:active,
button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  border-color: transparent;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: $white;
  border: 1px solid;
  border-radius: 0px;
  width: 100%;
  height: 60px;
  border: 1px solid $border3;
  background: $white;
  padding-left: 20px;  
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: $white;
  outline: none;
  box-shadow: none;
  border-color: $primary-color
}
select {
  border: 1px solid $text-color-2;
}
textarea {
	width: 100%;
	height: 150px;
	padding-top: 15px;
}
button,
button:hover,
button:focus {
	box-shadow: none;
	border: none;
	outline: none;
}
/*==========================================================================
Hedging CSS
==========================================================================*/
$list: (h1, 84px, 94px, 0, 0, $white, $heading-font, 700),
(h2, 48px, 58px, 0, 0, $white, $heading-font, 700),
(h3, 30px, 40px, 0, 0, $white, $heading-font, 700),
(h4, 24px, 34px, 0, 0, $white, $heading-font, 700),
(h5, 20px, 30px, 0, 0, $white, $heading-font, 700),
(h6, 18px, 28px, 0, 0, $white, $heading-font, 700),
(p, 16px, 26px, 0, 0, $text-color-4, $body-font);
@each $name, $size, $height, $p, $m, $color, $font, $weight in $list {
    #{$name}{
      font-size: $size;
      line-height: $height;
      padding: $p;
      margin: $m;
      color: $color;
      font-family: $font;
      font-weight: $weight;
    }
}
$list: left, center, right;
@each $i in $list {
    .t-#{$i} {
        text-align: $i;
    }
}
@media (max-width: 767px) {
	h2 {
		font-size: 46px;
		line-height: 56px;
	}
}
@media (max-width: 530px) {
	h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 460px) {
	h2 {
		font-size: 38px;
		line-height: 48px;
	}
}
@media (max-width: 430px) {
	h2 {
		font-size: 30px;
		line-height: 40px;
	}
	h3 {
		font-size: 26px;
		line-height: 36px;
	}
	h4 {
		font-size: 24px;
		line-height: 34px;
	}
}
/*==========================================================================
Theme Btn CSS
==========================================================================*/
.theme-btn,
.theme-btn-1 {
	background: $primary-color;
	color: $white;
	display: inline-flex;
	font-size: 16px;
	padding: 0 35px;
	text-align: center;
	font-weight: 700;
	font-family: $body-font;
	z-index: 3;
	position: relative;
	transition: all 0.5s ease-out 0s;
	align-items: center;
	line-height: 60px;
	overflow: hidden;
    &::after {
        position: absolute;
        content: '';
        position: absolute;
        width: 0;
		transition: all 0.5s ease-out 0s;
        height: 100%;
        top: 0;
        left: 0;
		right: 0;
		margin: 0 auto;
        z-index: -1;
    }
	i::before {
		font-size: 22px;
		margin-left: 10px;
		top: 1px;
		position: relative;
	}
    &:hover {
        color: $white;
        &::after {
			width: 100%;
        }
    }
	&:focus {
		color: $white;
	}
}
.theme-btn  {
	background: $primary-color-1;
	&:hover {
		color: $text-color-1
	}
	&::after {
		background: $white;
	}
}
.theme-btn-1  {
	background: $primary-color;
	&:hover {
		color: $text-color-1
	}
	&::after {
		background: $white;
	}
}
.simple-btn {
	display: inline-block;
	color: $white;
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
	transition: all 0.4s ease-in-out;
	text-transform: capitalize;
	i {
		position: relative;		
		top: 1px;
		margin-left: 8px;
	}
	&:hover {
		color: $primary-color;
	}
}
.subtitle {
	display: inline-block;
	position: relative;
	font-weight: 500;
	color: $primary-color;
	text-transform: capitalize;
	z-index: 1;
	margin-bottom: 6px;
	padding-left: 70px;
	font-family: $heading-font;
	&::after {
		position: absolute;
		top: 13px;
		content: '';
		left: 0;
		width: 50px;
		height: 1px;
		background: $primary-color;
	}
	&.one {
		color: $primary-color-1;
		&::after {
			background: $primary-color-1
		}		
	}
}
.subtitle-one {
	display: inline-block;
	font-family: $heading-font;
	position: relative;
	font-weight: 600;
	color: $primary-color;
	text-transform: capitalize;
	z-index: 1;
	margin-bottom: 6px;
}
@keyframes rotate {
	100% {
		transform: rotate(90deg);
	}
}
/*==========================================================================
Scroll Top CSS
==========================================================================*/
button.scroll-to-top {
	background-color: white;
	right: 20px;
	bottom: 20px;
	cursor: pointer;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	line-height: 50px;
	box-shadow: none;
	border: none;
	padding: 0;
	text-align: center;
	svg {
		width: 18px;
		height: 30px;
	}
}
/*==========================================================================
Custom Cursor
========================================================================== */
.custom__cursor-one {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid $primary-color;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 5px), -50%);
    z-index: 999999;
	&.main {
		border-color: $primary-color-1;
	}
}
.custom__cursor-two {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: $primary-color;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;
    z-index: 999999;            
	&.main {
		background: $primary-color-1;
	}
}
.custom__cursor__hover {
    background: $primary-color;
    opacity: 0.2;
    width: 70px;
    height: 70px;
	&.main {
		background: $primary-color-1;
		opacity: 0.4;
	}
}
.sortable-list {width: 75%; margin: 0 12.5%}
.sortable-item {width: 100%; display: flex; justify-content: space-between;margin: 2px; padding: 10px 15px; background: #272727 }
.sortable-item:nth-child(even) {background: #000}
.sortable-item a {float: left;}
.sortable-item span {float: right;} 