/*==========================================================================
Banner
==========================================================================*/
.banner__area {
    padding-top: 1rem;
    padding-bottom: 0px;
    background: $text-color-1;
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    &-content {
        padding-top: 145px;
        padding-bottom: 130px;
        h1 {
            font-size: 6em;
            line-height: 1em;
            margin-bottom: 30px;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            span {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 2px;
            }
        }
        p{
            font-size: 22px;
            color: #fff;
            margin-bottom: 30px;
            width: 65%;
        }
    }
    &-bottom {
        width: 100%;
        max-width: 1700px;
        padding: 0 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-15%) translateX(-50%);
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        z-index: 0;
        &-icon {
            ul {
                padding: 0;
                margin: 0;
                li {
                    margin-bottom: 10px;
                    list-style: none;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        i {
                            color: $white;
                            transition: all 0.4s ease-out 0s;
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            display: inline-block;
                            border: 1px solid $text-color-2;
                            text-align: center;
                            &:hover {
                                background: $primary-color-1;
                                border-color: $primary-color-1;
                            }
                        }
                    }
                }
            }        
        }
    }
}
/*==========================================================================
Banner Two
==========================================================================*/
.banner__area-two {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 100px;
    position: relative;
    &-content {
        padding-top: 185px;
        padding-bottom: 155px;
        span {
            font-family: $heading-font;
            font-weight: 500;
            color: $primary-color;
            margin-bottom: 15px;
            display: inline-block;
            position: relative;
            padding-left: 70px;
            &::after {
                position: absolute;
                top: 13px;
                content: '';
                left: 0;
                width: 50px;
                height: 1px;
                background: $primary-color;
            }
        }
        h1 {
            font-size: 150px;
            line-height: 160px;
            margin-bottom: 50px;
            text-transform: uppercase;
        }
    }
    &-bottom {
        text-align: right;
        padding: 0 50px; 
        &-icon {
            ul {
                padding: 0;
                margin: 0;
                li {
                    display: inline-block;
                    margin-right: 20px;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        i {
                            color: $white;
                            transition: all 0.3s ease-out 0s;
                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }        
        }
    }
}
/*==========================================================================
Banner Three
==========================================================================*/
.banner__area-three {
    padding-top: 200px;
    padding-bottom: 100px;
    background: $text-color-1;
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    &-content {
        text-align: center;
        padding-top: 125px;
        padding-bottom: 80px;
        h1 {
            font-size: 140px;
            line-height: 150px;
            margin-bottom: 60px;
            text-transform: uppercase;
            span {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1.5px;
            }
        }
    }
    &-bottom {
        &-icon {
            ul {
                padding: 0;
                margin: 0;
                li {
                    margin-right: 10px;
                    list-style: none;
                    display: inline-block;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        i {
                            color: $white;
                            transition: all 0.4s ease-out 0s;
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            display: inline-block;
                            border: 1px solid $text-color-2;
                            text-align: center;
                            &:hover {
                                background: $primary-color;
                                border-color: $primary-color;
                            }
                        }
                    }
                }
            }        
        }
    }
}
@media (max-width: 1700px) {
    .banner__area {
        &-bottom {
            padding: 0 60px;
        }
    }
}
@media (max-width: 1199px) {
    .banner__area-three {
        &-content {
            padding-top: 100px;
            padding-bottom: 110px;
            h1 {
                font-size: 110px;
                line-height: 120px;
            }
        }
        &-bottom {
            display: none;
        }
    }
}
@media (max-width: 991px) {
    .banner__area {
        &-bottom {
            display: none;
        }
        &-content {
            padding-top: 125px;
            padding-bottom: 110px;
            h1 {
                font-size: 94px;
                line-height: 104px;
            }
        }
    }
    .banner__area-two {
        &-content {
            h1 {
                font-size: 110px;
                line-height: 120px;
            }
        }
    }
    .banner__area-three {
        &-content {
            h1 {
                font-size: 86px;
                line-height: 96px;
                margin-bottom: 50px;
            }
        }
    }
}
@media (max-width: 767px) {
    .banner__area {
        &-content {
            h1 {
                font-size: 72px;
                line-height: 82px;
            }
        }
    }
    .banner__area-two {
        &-content {
            h1 {
                font-size: 84px;
                line-height: 94px;
            }
        }
        &-bottom {
            display: none;
        }
    }
    .banner__area-three {
        &-content {
            h1 {
                font-size: 64px;
                line-height: 74px;
            }
        }
    }
}
@media (max-width: 575px) {
    .banner__area {
        background-image: url(https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/8d66c253-63e8-4525-faf3-089ce3130d00/public) !important;
        &-content {
            padding-top: 80px;
            padding-bottom: 90px;
            h1 {
                font-size: 54px;
                line-height: 64px;
            }
        }
    }
    .banner__area-two {
        &-content {
            padding-top: 100px;
            padding-bottom: 70px;
            h1 {
                font-size: 64px;
                line-height: 74px;
            }
        }
    }
    .banner__area-three {
        &-content {
            padding-top: 80px;
            padding-bottom: 90px;
            h1 {
                font-size: 48px;
                line-height: 58px;
            }
        }
    }
}
@media (max-width: 402px) {
    .banner__area {
        &-content {
            h1 {
                font-size: 42px;
                line-height: 52px;
            }
        }
    }
    .banner__area-two {
        &-content {
            h1 {
                font-size: 58px;
                line-height: 68px;
            }
        }
    }
    .banner__area-three {
        &-content {
            h1 {
                font-size: 44px;
                line-height: 54px;
            }
        }
    }
}
@media (max-width: 359px) {
    .banner__area-two {
        &-content {
            h1 {
                font-size: 50px;
                line-height: 60px;
            }
        }
    }
    .banner__area-three {
        &-content {
            h1 {
                font-size: 38px;
                line-height: 48px;
            }
        }
    }
}