/*==========================================================================
Contact
==========================================================================*/
.contact__area {
    background: $text-color-8;
    ::-webkit-scrollbar {
        display: none;
    }    
    &-bottom {
        position: relative;
        &-form {
            width: 510px;
            background: $text-color-1;
            padding: 65px;
            position: absolute;
            top: -150px;
            right: 70px;
            z-index: 1;
            &-item {
                input {
                    color: $text-color-4;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid $text-color-2;
                    padding: 0;
                }
                label {
                    color: $text-color-4;
                    input {
                        accent-color: $primary-color;
                    }                
                }
                textarea {
                    color: $text-color-4;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid $text-color-2;
                    padding: 0;
                    height: auto;
                    line-height: 1.4em;
                    scrollbar-width: none;
                    resize: none;
                    &:focus {
                        border-color: $primary-color;
                    }
                }
            }
            &.page {
                top: -350px;                
            }
        }
        &-map {
            iframe {
                width: 100%;
                height: 570px;
                display: block;
                filter: grayscale(1);
            }
        }
    }
    &-info {
        margin-top: 30px;
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            &-icon {
                display: flex;
                align-items: center;
                span {
                    margin-left: 15px;
                    margin-right: 8px;
                    display: inline-block;
                }
            }
            &-content {
                a {
                    transition: all .4s ease-out 0s;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
            &:last-child {
                margin: 0;
            }
        }
    }
}
@media (max-width: 1199px) {
    .contact__area {
        &-bottom {
            &-form {
                &.page {
                    right: 30px; 
                    width: 430px;     
                    padding: 40px;              
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .contact__area {
        &-bottom {
            &-form {
                width: 100%;
                position: initial;
                margin-bottom: 30px;
                &.page {
                    width: 100%;            
                }
            }
        }
    }
}
@media (max-width: 575px) {
    .contact__area {
        &-bottom {
            &-form {
                padding: 50px;
            }
        }
    }
}
@media (max-width: 475px) {
    .contact__area {
        &-bottom {
            &-form {
                padding: 50px 30px;
                &.page {
                    padding: 50px 30px;        
                }
            }
        }
    }
}