/*==========================================================================
All Side Bar
========================================================================== */
.all__sidebar {
    &-item {
        &.special{
            padding: 40px;
            margin-bottom: 30px;
            background: $border4;
        }
        padding: 40px;
        margin-bottom: 30px;
        background: $border4;
        &:last-child {
            margin: 0;
        }
        &.services {
            background: $text-color-1;
        }
        h4 {
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 30px;
            &::before {
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                width: 100%;
                background: $white;
                opacity: 0.1;
            }
            &::after {
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                width: 120px;
                background: $primary-color;
            }
        }
        &-category {
            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    a {
                        display: block;
                        color: $text-color-4;
                        transition: all 0.4s ease-out 0s;
                        margin-bottom: 15px;
                        i {
                            margin-right: 5px;
                        }
                        span {
                            float: right;
                            position: relative;
                            top: 2px;
                        }
                        &:hover {
                            color: $primary-color;
                        }
                    }
                    &:last-child a {
                        margin: 0;
                    }
                }
            }
        }

    }
    &.specialSidebar {
        .one{
            padding: 0px;
            border-top: none;
            margin-top: 0px;
        }
    }
}
@media (max-width: 1399px) {
    .all__sidebar {
        &-item {
            padding: 35px 30px;
            &-help {
                padding: 30px;
                padding-top: 280px;
            }
        }
    }
}
@media (max-width: 1199px) {
    .all__sidebar {
        &-item {
            &.details {
                margin-left: 0;
            }
            &-post {
                &-item {
                    &-content {
                        h5 {                            
                            font-size: 16px;
                            line-height: 26px;
                            font-weight: 500;
                        }
                    }
                }
            }
            &-help {        
                padding-top: 200px;           
                &-contact {
                    &-icon {
                        margin-right: 15px;
                        i {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}